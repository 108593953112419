
/* stylelint-disable indentation */
	@import "./src/assets/styles/color.scss";
	.row {
		background-color: #4b3030;
		display: block;
		width: calc(100% + 16px);
		margin-left: -8px;
		margin-top: -8px;
		padding: 8px 0;
		box-shadow: 0 4px 0px $confirm;
	}
	h1 {
    font-size: 26px;
    font-weight: bold;
    color: #efefef;
    text-shadow: #303030 1px 1px;
	}
